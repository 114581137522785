import { createSlice } from '@reduxjs/toolkit'

interface ISettings {
  loadingBackdrop: boolean
}

const initialState: ISettings = {
  loadingBackdrop: false
}

export const settingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    toogleLoadingBackdrop: (state) => {
      state.loadingBackdrop = !state.loadingBackdrop
    }
  }
})

// Action creators are generated for each case reducer function
export const { toogleLoadingBackdrop } = settingsSlice.actions

export default settingsSlice.reducer
