import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface IDeleteConfirmDialogProps {
  deleteConfirmationDialogOpen: boolean
  handleDeleteDialogClose: () => void
  handleRemoveTrial: (appId: string) => void
  userName: string
  trialApplication: string
}
const DeleteConfirmationDialog: React.FC<IDeleteConfirmDialogProps> = ({
  deleteConfirmationDialogOpen,
  handleDeleteDialogClose,
  handleRemoveTrial,
  userName,
  trialApplication
}) => (
  <Dialog
    open={deleteConfirmationDialogOpen}
    onClose={handleDeleteDialogClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>Trial Remove Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        {`Are you sure you want to remove the trial for ${trialApplication} from ${userName}?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color='secondary' onClick={handleDeleteDialogClose}>
        Cancel
      </Button>
      <Button
        variant='contained'
        color='error'
        onClick={() => {
          handleRemoveTrial(trialApplication)
          handleDeleteDialogClose()
        }}
        autoFocus
      >
        Remove
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeleteConfirmationDialog
