import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import LockClockIcon from '@mui/icons-material/LockClock'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'

interface IMenuConfigItem {
  index: number
  name: string
  path: string
  desc: string
  icon?: JSX.Element
  type: string
  role: string[]
}
export const MenuConfig: IMenuConfigItem[] = [
  {
    index: 0,
    name: 'Actions',
    path: '',
    desc: '',
    type: 'divider',
    role: []
  },
  {
    index: 1,
    name: 'User Trials',
    path: 'dashboard/user/trials',
    desc: 'Provide a username and add or remove a trial to that user.',
    icon: <LockClockIcon />,
    type: 'link',
    role: ['SUPPORT', 'ADMIN', 'SUPPORTREADONLY']
  },
  {
    index: 2,
    name: 'User Feature Access',
    path: 'dashboard/user/softwareaccess',
    desc: 'Manage access to various software packages',
    icon: <ManageAccountsIcon />,
    type: 'link',
    role: ['DEV', 'ADMIN']
  },
  {
    index: 3,
    name: 'User Feature Flags',
    path: 'dashboard/user/featureflags',
    desc: 'Manage software packages (such as DEV/RC) for a user',
    icon: <MiscellaneousServicesIcon />,
    type: 'link',
    role: ['DEV', 'ADMIN']
  },
  {
    index: 4,
    name: 'Account Entitlements',
    path: 'dashboard/account/entitlements',
    desc: 'Add / Remove Full Entitlement License from an Account',
    icon: <SupervisorAccountIcon />,
    type: 'link',
    role: ['SUPPORT', 'ADMIN', 'SUPPORTREADONLY']
  },
  {
    index: 5,
    name: 'Admin Actions',
    path: '',
    desc: '',
    type: 'divider',
    role: ['ADMIN']
  },
  {
    index: 6,
    name: 'GEMS Users',
    path: 'dashboard/admin/users',
    desc: 'Manage GEMS user access and accounts',
    icon: <SupervisorAccountIcon />,
    type: 'link',
    role: ['ADMIN']
  },
  {
    index: 7,
    name: 'Key Tools',
    path: 'dashboard/key-admin-tool',
    desc: 'Manage Key Tools',
    icon: <SupervisorAccountIcon />,
    type: 'link',
    role: ['ADMIN']
  }
]
