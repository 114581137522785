import React, { useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import { convertAppId } from '../data/Helpers'

interface IExtendTrialDialogProps {
  extendDialogOpen: boolean
  handleExtendDialogClose: () => void
  handleExtendTrial: (username: string, applicationId: string, days: number) => void
  userName: string
  trialApplication: string
}

const ExtendTrialDialog: React.FC<IExtendTrialDialogProps> = ({
  extendDialogOpen,
  handleExtendDialogClose,
  handleExtendTrial,
  userName,
  trialApplication
}) => {
  const defaultExtensionTimeInDays = 14
  const [extensionDays, setExtensionDays] = useState<number>(defaultExtensionTimeInDays)
  return (
    <Dialog
      open={extendDialogOpen}
      onClose={handleExtendDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{`Trial ${trialApplication} Extension`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {'How many days do you want to extend '}
          <Typography variant='overline' color='primary'>
            {userName}
          </Typography>
          {"'s trial for "}
          <Typography variant='overline' color='primary'>
            {trialApplication}
          </Typography>
        </DialogContentText>
        <Grid>
          <Grid item justifyContent='center' alignItems='center' alignContent='center'>
            <TextField
              value={extensionDays}
              id='input-days'
              label='Days'
              type='number'
              onChange={(e) => {
                setExtensionDays(Number(e.target.value))
              }}
              fullWidth
              variant='filled'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          onClick={() => {
            setExtensionDays(defaultExtensionTimeInDays)
            handleExtendDialogClose()
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handleExtendTrial(userName, convertAppId(trialApplication), extensionDays)
            setExtensionDays(defaultExtensionTimeInDays)
            handleExtendDialogClose()
          }}
          autoFocus
        >
          Extend
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ExtendTrialDialog
