import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ILogin {
  isAdmin: boolean
  isSuperAdmin: boolean
}
interface IKeyCodes extends ILogin {
  keyCodes: IKeyCode[]
}
export interface IKeyCode {
  ApplicationStartupDate: string
  ApplicationStatus: number
  KeyEnabled: number
  UserTypeId: number
  KeyProfileId: number
  CompanyName: null | string
  GemvisionAccountNumber: string
  StullerShipToAccountNo: string
  ExpireDateLibrary: string
  PrimaryEmail: string
  KeyCountryCode: string
  WindowsProductKey: string | null
  ApplicationStatusDate: string
  CompId1: Buffer
  ExpireDateCounterSketch: string
  ExportDesignBonus: null | number
  LocationLAT: number
  LocationLONG: number
  Name: string
  StullerShipToAddress1: string
  StullerShipToCity: string
  StullerShipToState: string
  StullerShipToZip: string
  VersionCurrentCounterSketchId: number
  VersionCurrentLibraryId: number
  VersionCurrentPermitLibraryId: number
  VersionCurrentRhino: string
  VersionPermitCounterSketchId: number
  VersionPermitLibraryId: number
  WindowsVersion: null
  codes: Buffer
  KeyId: number
}

const initialState: IKeyCodes = {
  keyCodes: [],
  isAdmin: false,
  isSuperAdmin: false
}

export const keyCodesSlice = createSlice({
  name: 'KeyCodes',
  initialState,
  reducers: {
    resetKeys: (state) => {
      state.keyCodes = []
    },
    getKeyCodes: (state, action: PayloadAction<IKeyCode[]>) => {
      state.keyCodes = [...state.keyCodes, ...action.payload]
    },
    addKeyCode: (state, action: PayloadAction<IKeyCode[]>) => {
      state.keyCodes = [...action.payload, ...state.keyCodes]
    },
    updateKeyCode: (state, action: PayloadAction<IKeyCode>) => {
      const registerToUpdate = state.keyCodes.find((item) => action.payload.KeyId === item.KeyId)
      if (registerToUpdate) {
        registerToUpdate.ExportDesignBonus = action.payload.ExportDesignBonus
        registerToUpdate.ExpireDateLibrary = action.payload.ExpireDateLibrary
        registerToUpdate.KeyProfileId = action.payload.KeyProfileId
        registerToUpdate.UserTypeId = action.payload.UserTypeId
        registerToUpdate.KeyEnabled = action.payload.KeyEnabled
      }
    },
    loginKeyAdmin: (state, action: PayloadAction<ILogin>) => {
      state.isAdmin = action.payload.isAdmin
      state.isSuperAdmin = action.payload.isSuperAdmin
    },
    logoutKeyAdmin: (state) => {
      state.isAdmin = false
      state.isSuperAdmin = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { getKeyCodes, addKeyCode, updateKeyCode, loginKeyAdmin, logoutKeyAdmin, resetKeys } =
  keyCodesSlice.actions

export default keyCodesSlice.reducer
