import React, { ReactNode } from 'react'

interface PublicRouteProps {
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-shadow
// eslint-disable-next-line max-len
const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => <>{children}</>

export default PublicRoute
