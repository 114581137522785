import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, TextField, Stack } from '@mui/material'
import { BACKEND_URL } from '../../constants'
import axios from 'axios'
import { addKeyCode } from '../../redux/KeyCodes'
import { toast } from 'react-toastify'
import { toogleLoadingBackdrop } from '../../redux/Settings'

export default function KeyAdminAddKey() {
  const [numberOfKey, setNumberOfKey] = useState(1)
  const dispatch = useDispatch()

  async function addKeyCodes() {
    let response
    try {
      dispatch(toogleLoadingBackdrop())
      response = await axios.post(
        `${BACKEND_URL}/key-codes`,
        { numberOfKey },
        { headers: { Authorization: process.env.REACT_APP_current_stage === 'local' ? null : 'allow' } }
      )
      dispatch(addKeyCode(response.data))
      toast.success(`${numberOfKey} new key/s have been added`, { theme: 'colored' })
    } catch (error: any) {
      console.error(error.message)
      toast.error('Something wrong happend, try again later', { theme: 'colored' })
    }
    dispatch(toogleLoadingBackdrop())
  }

  return (
    <Stack direction={'row'} alignItems={'center'} gap={2}>
      <TextField
        id='outlined-number'
        label='Number'
        type='number'
        size='small'
        InputLabelProps={{
          shrink: true
        }}
        value={numberOfKey}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          Number(event.target.value) >= 1 && setNumberOfKey(Number(event.target.value))
        }
      />
      <Button variant='contained' onClick={addKeyCodes}>
        Add Key(s)
      </Button>
    </Stack>
  )
}
