import * as React from 'react'
import { Grid } from '@mui/material'

const EntitlementsView = () => {
  document.title = 'Gems Admin Portal - Dashboard'
  const dashHeight = '720px'

  return (
    <>
      <Grid container justifyContent='center'>
        {/* DASH */}
        <Grid item xs={10} style={{ height: dashHeight }} />
      </Grid>
    </>
  )
}

export default EntitlementsView
