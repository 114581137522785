import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import React from 'react'
import { IKeyCode } from '../../redux/KeyCodes'
import { TextCopied, keyProfileEnum, userTypeEnum } from '../../constants'
import { Stack } from '@mui/system'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

interface IKeyAdminDialog {
  openDialog: boolean
  handleClose: () => void
  keyCodeSelection: IKeyCode | null
  handlerOnSubmit: React.FormEventHandler<HTMLFormElement>
  setInputs: React.Dispatch<React.SetStateAction<IKeyCode | null>>
}

export default function KeyAdminDialog(props: IKeyAdminDialog) {
  const { openDialog, handleClose, keyCodeSelection, handlerOnSubmit, setInputs } = props
  const [open, setOpen] = React.useState(false)
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)

  function handleTooltipClose() {
    setOpen(false)
  }

  if (keyCodeSelection === null) return null
  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth>
      <DialogTitle>Subscribe</DialogTitle>
      <form onSubmit={handlerOnSubmit}>
        <DialogContent
          sx={{ maxWidth: 1800, display: 'grid', gridTemplateColumns: { xs: '1fr', md: 'repeat(2,1fr)' }, gap: 4 }}
        >
          <Stack>
            <Typography>Key: {keyCodeSelection['KeyId'] ?? ''}</Typography>
            <Typography>Company Name: {keyCodeSelection['CompanyName'] ?? ''}</Typography>
            <Typography>Name: {keyCodeSelection['Name'] ?? ''} </Typography>
            <Typography>Email: {keyCodeSelection['PrimaryEmail'] ?? ''} </Typography>
            <Typography>Gemvision Account Number: {keyCodeSelection['GemvisionAccountNumber'] ?? ''} </Typography>
            <Typography>Stuller Ship To Account No.: {keyCodeSelection['StullerShipToAccountNo'] ?? ''} </Typography>
            <Typography>Address: {keyCodeSelection['StullerShipToAddress1'] ?? ''} </Typography>
            <Typography>City: {keyCodeSelection['StullerShipToCity'] ?? ''} </Typography>
            <Typography>State: {keyCodeSelection['StullerShipToState'] ?? ''} </Typography>
            <Typography>Country Code: {keyCodeSelection['KeyCountryCode'] ?? ''} </Typography>
            <Typography>Zip: {keyCodeSelection['StullerShipToZip'] ?? ''} </Typography>
          </Stack>
          <Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <TextField
                color='secondary'
                margin='dense'
                id={'Windows Product Key'}
                label={'Windows Product Key'}
                variant='outlined'
                value={keyCodeSelection['WindowsProductKey'] ?? ''}
                fullWidth
                disabled
              />
              {keyCodeSelection['WindowsProductKey'] !== null && (
                <Tooltip
                  title={TextCopied}
                  open={open}
                  onClose={handleTooltipClose}
                  leaveDelay={1000}
                  placement='top'
                  arrow
                >
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      if (keyCodeSelection['WindowsProductKey'] !== null) {
                        setOpen(true)
                        navigator.clipboard.writeText(keyCodeSelection['WindowsProductKey'])
                      }
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <TextField
              label={'Expire Date Library'}
              defaultValue={keyCodeSelection['ExpireDateLibrary'] ?? null}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, ExpireDateLibrary: event.target.value }
                )
              }
              type='date'
              color='secondary'
              margin='dense'
              name={'ExpireDateLibrary'}
              id={'Expire Date Library'}
              variant='outlined'
              fullWidth
            />
            <FormControl fullWidth margin='dense'>
              <InputLabel id='demo-simple-select-label'>Key Profile Id</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='KeyProfileId'
                defaultValue={keyCodeSelection['KeyProfileId']}
                label='Key Profile Id'
                disabled={!isAdmin}
                onChange={(event: SelectChangeEvent<number>) =>
                  setInputs((state: IKeyCode | null) =>
                    state === null ? null : { ...state, KeyProfileId: Number(event.target.value) }
                  )
                }
              >
                {Object.entries(keyProfileEnum).map((item) => (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='dense'>
              <InputLabel id='demo-simple-select-label'>User Type Id</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='UserTypeId'
                defaultValue={keyCodeSelection['UserTypeId']}
                label='User Type Id'
                disabled={!isAdmin}
                onChange={(event: SelectChangeEvent<number>) =>
                  setInputs((state: IKeyCode | null) =>
                    state === null ? null : { ...state, UserTypeId: Number(event.target.value) }
                  )
                }
              >
                {Object.entries(userTypeEnum).map((item) => (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              name={'KeyEnabled'}
              control={
                <Checkbox
                  defaultChecked={Boolean(keyCodeSelection['KeyEnabled'])}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setInputs((state: IKeyCode | null) =>
                      state === null ? null : { ...state, KeyEnabled: Number(event.target.checked) }
                    )
                  }
                />
              }
              label='Key Enabled'
            />
            <TextField
              color='secondary'
              type='number'
              margin='dense'
              id={'ExportDesignBonus'}
              name={'ExportDesignBonus'}
              label={'Export Design Bonus'}
              variant='outlined'
              defaultValue={keyCodeSelection['ExportDesignBonus']}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setInputs((state: IKeyCode | null) =>
                  state === null ? null : { ...state, ExportDesignBonus: Number(event.target.value) }
                )
              }
            />
            next value a don't know what it is
            <FormControlLabel disabled control={<Checkbox disabled />} label='Has Export Feature' />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
