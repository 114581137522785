import { FC, Fragment } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IAccordionSectionProps {
  sectionName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: Array<any>
}
export const AccordionSection: FC<IAccordionSectionProps> = ({ sectionName, content }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant='overline'>{sectionName}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {content.map((node) => (
        <Fragment key={node.props.applicationID}>{node}</Fragment>
      ))}
    </AccordionDetails>
  </Accordion>
)
