import { Chip, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IAppRecordDescription, IApplicationOwned } from '../../data/Helpers'
import MinusIcon from '@mui/icons-material/ArrowDownward'
import PlusIcon from '@mui/icons-material/ArrowUpward'
import NoChangeIcon from '@mui/icons-material/FeaturedPlayList'
import UnknownIcon from '@mui/icons-material/Help'
import { GridColDef } from '@mui/x-data-grid'

interface IRenderApplicationOffsetControl {
  amount: number
  appId: string
  readOnlyAccess: boolean
  setApplicationOwnedAmounts: React.Dispatch<React.SetStateAction<IApplicationOwned>>
}

export const RenderApplicationOffsetControl = (props: IRenderApplicationOffsetControl) => {
  const { amount, appId, readOnlyAccess, setApplicationOwnedAmounts } = props
  const [value, setValue] = useState<number>(amount)
  return (
    <TextField
      disabled={readOnlyAccess}
      id='outlined-number'
      label='Amount'
      type='number'
      size='small'
      value={value}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(Number(e.target.value))
        setApplicationOwnedAmounts((prevState) => ({
          ...prevState,
          [appId]: Number(e.target.value) < 0 ? 0 : Number(e.target.value)
        }))
      }}
    />
  )
}

export const RenderCreationDate = ({ date }: { date: number }) => {
  if (!date || date === 0) {
    return <></>
  }
  const formattedDate = new Date(date * 1000).toISOString().slice(0, -8)

  return <TextField id='outlined-number' type='datetime-local' size='small' value={formattedDate} disabled />
}

interface IRenderExpirationDate {
  date: number
  appId: string
  readOnlyAccess: boolean
  applicationCollectionData: Record<string, IAppRecordDescription>
  setApplicationOwnedExpiration: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number | null
    }>
  >
}

export const RenderExpirationDate = (props: IRenderExpirationDate) => {
  const { date, appId, readOnlyAccess, applicationCollectionData, setApplicationOwnedExpiration } = props
  const formattedDate = new Date(date * 1000).toISOString().slice(0, -8)
  const [value, setValue] = useState<string>(formattedDate)

  if (!applicationCollectionData[appId as keyof typeof applicationCollectionData].isSubscription) {
    return <></>
  }

  return (
    <TextField
      disabled={readOnlyAccess}
      id='outlined-number'
      type='datetime-local'
      size='small'
      value={value}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value)
        const newDate = new Date(e.target.value)
        const expirationDate = Math.trunc(newDate.getTime() / 1000 - newDate.getTimezoneOffset() * 60)
        setApplicationOwnedExpiration((prevState) => ({
          ...prevState,
          [appId]: expirationDate
        }))
      }}
    />
  )
}

interface IRenderEntitlementAccountChange {
  appId: string
  applicationOwnedAmounts: IApplicationOwned
  initialApplicationOwnedAmounts: IApplicationOwned
  applicationOwnedExpiration: IApplicationOwned
  initialApplicationOwnedExpiration: IApplicationOwned
  loading: boolean
}

export const RenderEntitlementAccountChange = (props: IRenderEntitlementAccountChange) => {
  const {
    appId,
    applicationOwnedAmounts,
    initialApplicationOwnedAmounts,
    applicationOwnedExpiration,
    initialApplicationOwnedExpiration,
    loading
  } = props
  const amountDiff =
    (Number(applicationOwnedAmounts[appId]) ?? 0) - (Number(initialApplicationOwnedAmounts[appId]) ?? 0)
  let expirationDiff =
    (Number(applicationOwnedExpiration[appId]) ?? 0) - (Number(initialApplicationOwnedExpiration[appId]) ?? 0)
  expirationDiff /= 86400 // Converted to days
  const chipColor = (diff: number) => {
    if (isNaN(diff)) {
      return 'info'
    }
    if (diff === 0) {
      return 'primary'
    }
    if (diff > 0) {
      return 'success'
    }
    return 'error'
  }
  const chipIcon = (diff: number) => {
    if (isNaN(diff)) {
      return <UnknownIcon fontSize='small' />
    }
    if (diff === 0) {
      return <NoChangeIcon fontSize='small' />
    }
    if (diff > 0) {
      return <PlusIcon fontSize='small' />
    }
    return <MinusIcon fontSize='small' />
  }

  if (loading) {
    return <CircularProgress color='primary' />
  }

  if (amountDiff === 0 && expirationDiff === 0) {
    return <Typography variant='overline'>No Change</Typography>
  }

  return (
    <>
      {amountDiff !== 0 && (
        <Chip
          label={`${amountDiff} license/s`}
          color={chipColor(amountDiff)}
          icon={chipIcon(amountDiff)}
          variant='filled'
        />
      )}
      {expirationDiff !== 0 && (
        <Chip
          label={`${expirationDiff.toFixed(2)} day/s`}
          color={chipColor(expirationDiff)}
          icon={chipIcon(expirationDiff)}
          variant='filled'
        />
      )}
    </>
  )
}

interface IGetColumns {
  readOnlyAccess: boolean
  setApplicationOwnedAmounts: React.Dispatch<React.SetStateAction<IApplicationOwned>>
  applicationCollectionData: Record<string, IAppRecordDescription>
  setApplicationOwnedExpiration: React.Dispatch<React.SetStateAction<IApplicationOwned>>
  applicationOwnedAmounts: IApplicationOwned
  initialApplicationOwnedAmounts: IApplicationOwned
  applicationOwnedExpiration: IApplicationOwned
  initialApplicationOwnedExpiration: IApplicationOwned
  loading: boolean
}

export const getColumns = (props: IGetColumns): GridColDef[] => {
  const {
    readOnlyAccess,
    setApplicationOwnedAmounts,
    applicationCollectionData,
    setApplicationOwnedExpiration,
    applicationOwnedAmounts,
    initialApplicationOwnedAmounts,
    applicationOwnedExpiration,
    initialApplicationOwnedExpiration,
    loading
  } = props
  const columns: GridColDef[] = [
    {
      field: 'app_name',
      headerName: 'Application',
      width: 200,
      sortable: false
    },
    {
      field: 'offset_control',
      headerName: 'License Amount Owned',
      renderCell: (params) =>
        RenderApplicationOffsetControl({ ...params.value, readOnlyAccess, setApplicationOwnedAmounts }),
      width: 200,
      sortable: false
    },
    {
      field: 'creation_date',
      headerName: 'Creation Date',
      renderCell: (params) => RenderCreationDate(params.value),
      width: 250,
      sortable: false
    },
    {
      field: 'expiration_date',
      headerName: 'Expiration Date',
      renderCell: (params) =>
        RenderExpirationDate({
          ...params.value,
          readOnlyAccess,
          applicationCollectionData,
          setApplicationOwnedExpiration
        }),
      width: 250,
      sortable: false
    },
    {
      field: 'difference',
      headerName: 'Change',
      renderCell: (params) =>
        RenderEntitlementAccountChange({
          ...params.value,
          applicationOwnedAmounts,
          initialApplicationOwnedAmounts,
          applicationOwnedExpiration,
          initialApplicationOwnedExpiration,
          loading
        }),
      width: 300,
      sortable: false
    }
  ]
  return columns
}
