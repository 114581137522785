import React from 'react'
import './index.css'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/system'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './store'

import 'react-toastify/dist/ReactToastify.css'

import { theme } from './theme'
// Apollo Gql Client
const client = new ApolloClient({
  uri: process.env.REACT_APP_GATEWAY_URL,
  cache: new InMemoryCache()
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ToastContainer />
        <Router>
          <CssBaseline>
            <App />
          </CssBaseline>
        </Router>
      </ApolloProvider>
    </Provider>
  </ThemeProvider>
)
