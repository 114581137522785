import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { Stack, Typography, IconButton, MenuItem, Menu, Box, Avatar } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { MoreVert } from '@mui/icons-material'
import { logoutCurrentUser } from '../redux/Authentication'
import { RootState } from '../store'

const UserInfo = () => {
  const dispatch = useDispatch()
  const username = useSelector((state: RootState) => state.authenticationReducer.rootStore.userStore.username)
  const rolesStore = useSelector((state: RootState) => state.authenticationReducer.rootStore.rolesStore)
  const history = useNavigate()
  const apolloClient = useApolloClient()
  // jank nasty
  // Empties the token strings and routes to login page
  const handleLogout = (): void => {
    dispatch(logoutCurrentUser())
    history('/login')
    apolloClient.cache.reset()
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box pl={2} pr={1} py={2}>
      <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
        <Avatar sx={{ textTransform: 'uppercase', bgcolor: 'primary.main' }}>{username.toString()[0]}</Avatar>
        <Stack px={2}>
          <Typography>{username}</Typography>
          <Typography variant='body2' color='primary.light'>
            {rolesStore}
          </Typography>
        </Stack>
        <IconButton onClick={handleClick} style={{ marginLeft: 'auto' }}>
          <MoreVert />
        </IconButton>
      </Stack>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </Box>
  )
}

export default UserInfo
