import { Button, Grid, Typography } from '@mui/material'
import { ReactElement } from 'react'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import { useNavigate } from 'react-router-dom'
import FullWidthCenteredLayout from '../layouts/FullWidthCenteredLayout'
import { useDispatch } from 'react-redux'
import { logoutCurrentUser } from '../redux/Authentication'
import { useApolloClient } from '@apollo/client'

const ForbiddenView = (): ReactElement => {
  document.title = 'Gems Admin Portal - Forbidden'
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()

  function handleLogout() {
    dispatch(logoutCurrentUser())
    navigate('/login', { replace: true })
    apolloClient.cache.reset()
  }
  return (
    <FullWidthCenteredLayout direction='column' marginTop={10}>
      <Grid container style={{ textAlign: 'center' }} justifyContent='center'>
        <Grid item xs={12}>
          <ReportProblemRoundedIcon fontSize='large' color='error' />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='overline'>Invalid Permissions To View This Page.</Typography>
        </Grid>
        <Button onClick={handleLogout}>Back to Login View</Button>
      </Grid>
    </FullWidthCenteredLayout>
  )
}

export default ForbiddenView
