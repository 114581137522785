import { gql } from '@apollo/client'

export const GET_APP_DATA = gql`
  query Apps {
    listApplications(version: "v0") {
      items {
        applicationID
        applicationName
        features {
          featureID
          featureName
        }
        optionalFeatures {
          featureOptionName
          features {
            featureID
            featureName
          }
        }
      }
    }
  }
`

export const GET_USER_FEATURE_FLAGS = gql`
  query userFeatureFlags($username: String!) {
    getGemsUser(username: $username) {
      userID
      username
      userSoftwareOptions {
        appId
        options
      }
    }
  }
`

export const SET_USER_APP_OPTIONS = gql`
  mutation userAppSoftwareOptions($userID: String!, $appID: String!, $options: [String]!) {
    setUserAppSoftwareOptions(userId: $userID, appId: $appID, options: $options) {
      appId
      options
    }
  }
`
