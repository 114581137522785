import XLSX from 'sheetjs-style'
import FileSaver from 'file-saver'
import { IGemsUserReport } from '../graphql/types/accountTypes'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

export const exportExcel = async (data: IGemsUserReport[], columns: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finalData = data.map((row: any) => columns.reduce((acc: any, curr) => ({ ...acc, [curr]: row[curr] }), {}))
  const ws = XLSX.utils.json_to_sheet(finalData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const saveData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(saveData, 'report.xlsx')
}
