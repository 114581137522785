import { configureStore } from '@reduxjs/toolkit'
import authenticationReducer from './redux/Authentication'
import keyCodesReducer from './redux/KeyCodes'
import settingReducer from './redux/Settings'

export const store = configureStore({
  reducer: { authenticationReducer, keyCodesReducer, settingReducer }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
