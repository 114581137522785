import { ApolloError, useMutation } from '@apollo/client'
import {
  Box,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Typography,
  LinearProgress
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { convertAppId } from '../../data/Helpers'
import { SET_USER_SOFTWARE_ACCESS } from '../../graphql/queries/accountQueries'
import { ISoftwareAccessResult } from '../../views/UserSoftwareAccess'
import { RootState } from '../../store'

interface ISoftwareAccessComponentProps {
  userID: string
  username: string
  applicationID: string
  gemsUserData: Array<ISoftwareAccessResult>
}
const SoftwareAccessComponent: FC<ISoftwareAccessComponentProps> = ({
  userID,
  username,
  applicationID,
  gemsUserData
}) => {
  const [devFlag, setDevFlag] = useState(false)
  const [rcFlag, setRcFlag] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)
  const [setUserSoftwareAccess] = useMutation(SET_USER_SOFTWARE_ACCESS, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    fetchPolicy: 'network-only',
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  // eslint-disable-next-line max-len
  const filterAppFlag = (flagName: string): ISoftwareAccessResult | undefined =>
    gemsUserData.find((d) => d.applicationID === applicationID && d.flagName === flagName)

  const handleFlagMutation = (flagValue: boolean, flagName: string) => {
    setLoading(true)
    setUserSoftwareAccess({
      variables: {
        userID,
        applicationID,
        flagName,
        flag: flagValue
      }
    })
      .then((data) => {
        console.log(JSON.stringify(data))
        toast.success(`${username}: ${convertAppId(applicationID)} ${flagName} set to ${flagValue}`)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'dev') {
      setDevFlag(event.target.checked)
      handleFlagMutation(event.target.checked, 'DEV')
    }
    if (event.target.id === 'rc') {
      setRcFlag(event.target.checked)
      handleFlagMutation(event.target.checked, 'RC')
    }
  }

  useEffect(() => {
    const devSoftwareAccess = filterAppFlag('DEV')
    const rcSoftwareAccess = filterAppFlag('RC')
    if (devSoftwareAccess !== undefined) {
      setDevFlag(devSoftwareAccess.flag)
    }
    if (rcSoftwareAccess !== undefined) {
      setRcFlag(rcSoftwareAccess.flag)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gemsUserData, setRcFlag, setDevFlag])

  return (
    <Box px={2} pb={1}>
      <FormGroup>
        <FormLabel>
          <Typography variant='button' color='primary.light'>
            Software Release Access
          </Typography>
        </FormLabel>
        <FormControlLabel control={<Checkbox id='dev' checked={devFlag} onChange={handleChange} />} label='Developer' />
        <FormControlLabel
          control={<Checkbox id='rc' checked={rcFlag} onChange={handleChange} />}
          label='Release Candidate'
        />
        <FormHelperText>Allows a user to view/toggle these options.</FormHelperText>
        <LinearProgress sx={{ display: loading ? 'block' : 'none' }} />
      </FormGroup>
    </Box>
  )
}

export default SoftwareAccessComponent
