import { keyProfileEnum, statusEnum, userTypeEnum } from '../constants'

export function renderEnum(field: string, value: string | number) {
  switch (field) {
    case 'ApplicationStatus':
      return statusEnum[value]
    case 'UserTypeId':
      return userTypeEnum[value]
    case 'KeyProfileId':
      return keyProfileEnum[value]
    default:
      return ''
  }
}
