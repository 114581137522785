/* eslint-disable max-len */
import { gql } from '@apollo/client'

export const LIST_ACCOUNT_RECORDS = gql`
  query {
    listAccountBaseRecords {
      items {
        AccountNumber
        accountID
        CompanyName
        PhoneNumber
        AddressLine1
        City
        State
        Zip
        Country
        trialsEnabled
        trialsIssuedForAccount
      }
    }
  }
`

export const LIST_GEMS_USERS_REPORT = gql`
  query queryListGemsUsersReport {
    listGemsUsersReport {
      AccountNumber
      AccountName
      Email
      PhoneNumber
      CompanyName
      AddressLine
      Country
      State
      City
      Zip
      MG2
      MG2Date
      MG3
      MG3Date
      MGMembership
      MGMembershipDate
      CS
      CSDate
      CSStreaming
      CSStreamingDate
      CSMembership
      CSMembershipDate
    }
  }
`

export const LIST_ACCOUNT_ENTITLEMENTS = gql`
  query queryAccountEntitlements($accountId: String) {
    listAccountAppRecords(accountId: $accountId) {
      items {
        accountID
        appId
        amount
        expirationDate
        creationDate
      }
    }
  }
`

export const LIST_ACCOUNT_ENTITLEMENTS_BY_EMAIL = gql`
  query queryAccountEntitlements($email: String!) {
    listAccountAppRecordsByEmail(email: $email) {
      email
      username
      billTo
      firstName
      lastName
      items {
        accountID
        appId
        amount
        expirationDate
        creationDate
      }
    }
  }
`

export const OFFSET_CREATE_ENTITLEMENT = gql`
  mutation offsetCreateEntitlement($accountID: String!, $appId: String!, $amount: Int!, $expiry: Int) {
    offsetClaims(accountID: $accountID, appId: $appId, amount: $amount, expiry: $expiry) {
      accountID
      appId
      amount
      expirationDate
      creationDate
    }
  }
`

export const SET_USER_SOFTWARE_ACCESS = gql`
  mutation setSoftwareAccess($userID: String!, $applicationID: String!, $flagName: String!, $flag: Boolean!) {
    setUserSoftwareAccess(userID: $userID, applicationID: $applicationID, flagName: $flagName, flag: $flag) {
      applicationID
      flagName
      flag
    }
  }
`
