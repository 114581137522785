//@ts-ignore
import { useQuery } from '@apollo/client'
import { LinearProgress } from '@mui/material'
import { FunctionComponent, useEffect, useState, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import dayjs from 'dayjs'
import { print } from 'graphql/language/printer'
import { GET_USER_ROLES } from '../../graphql/queries/roleQueries'
import ForbiddenView from '../../views/ForbiddenView'
import { RootState } from '../../store'
import { setAccessTokenValue, setExpirationTime, setRoles } from '../../redux/Authentication'
import { LOGIN_TOKEN } from '../../graphql/queries/authenticationQueries'
import { IAuthenticateResponse } from '../../graphql/types/authenticationTypes'
const crypto = require('crypto-browserify')
interface IRoleRouteProps {
  children: ReactNode
  requiredRole: Array<string>
}

// This whole component is not being used
const RoleRequiredRoute: FunctionComponent<IRoleRouteProps> = ({ children, requiredRole }) => {
  const dispatch = useDispatch()
  const expirationTime = useSelector((state: RootState) => state.authenticationReducer.expirationTime)
  const refreshToken = useSelector((state: RootState) => state.authenticationReducer.refreshToken)
  const accessToken = useSelector((state: RootState) => state.authenticationReducer.accessToken)
  // eslint-disable-next-line max-len
  const userStoreHashed = useSelector((state: RootState) =>
    crypto.createHash('sha256').update(state.authenticationReducer.rootStore.userStore.username).digest('hex')
  )
  const [validRolesFound, setValidRolesFound] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  // Query current user roles
  const { data, error } = useQuery(GET_USER_ROLES, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    variables: {
      userId: userStoreHashed
    }
  })

  useEffect(() => {
    if (data) {
      // take the intersection of the user's roles, and the roles required for this route,
      // second filter removes duplicates if any
      const matchedRequiredRolesCollection: Array<string> = data.listUserRoles.items
        .filter((value: { role: string }) => requiredRole.includes(value.role))
        .filter((e: string, i: number, c: string[]) => c.indexOf(e) === i)
      // console.log(`roles: ${JSON.stringify(matchedRequiredRolesCollection)}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(setRoles(matchedRequiredRolesCollection.map((item: any) => item.role)))
      // console.log(`roles: ${JSON.stringify(rolesStore)}`);
      if (matchedRequiredRolesCollection.length > 0) {
        setValidRolesFound(true)
      }
      setLoading(false)
    } else if (error) {
      setLoading(false)
    }
  }, [data, error, requiredRole, dispatch])

  useEffect(() => {
    const checkExpirationDate = async () => {
      try {
        if (dayjs(expirationTime).isBefore(dayjs())) {
          const request = await axios({
            url: `${process.env.REACT_APP_GATEWAY_URL}`,
            method: 'post',
            data: {
              query: print(LOGIN_TOKEN),
              variables: {
                refreshToken
              }
            }
          })
          const response = request.data.data.loginWithToken as IAuthenticateResponse
          dispatch(setAccessTokenValue(response.AccessToken))
          dispatch(setExpirationTime(response.ExpiresIn))
        }
      } catch (err) {
        console.error(err)
        dispatch(setAccessTokenValue(''))
        dispatch(setExpirationTime(0))
      }
    }
    checkExpirationDate()
  }, [dispatch, expirationTime, refreshToken])

  return <>{loading ? <LinearProgress /> : <>{validRolesFound ? <>{children}</> : <ForbiddenView />}</>}</>
}

export default RoleRequiredRoute
