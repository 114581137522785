import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { localStorageKeys } from '../constants'
import validateRole from '../utils/validateRole'

interface AuthenticationState {
  rootStore: {
    userStore: { username: string }
    rolesStore: string[]
  }
  accessToken: string
  refreshToken: string
  isAuthenticated: boolean
  expirationTime: string
}

const initialState: AuthenticationState = {
  rootStore: {
    userStore: {
      username: localStorage.getItem(localStorageKeys.username) || ''
    },
    rolesStore: []
  },
  accessToken: '',
  refreshToken: localStorage.getItem(localStorageKeys.refreshToken) || '',
  isAuthenticated: false,
  expirationTime: dayjs().format()
}

export const authenticationSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => ({
      ...state,
      rootStore: {
        ...state.rootStore,
        userStore: {
          ...state.rootStore.userStore,
          username: action.payload
        }
      }
    }),
    // // nextLine must to be test it
    // setUsername: (state, action: PayloadAction<string>) => {
    //   state.rootStore.userStore.username=action.payload
    // },
    addRole: (state, action: PayloadAction<string>) => {
      if (validateRole(action.payload)) state.rootStore.rolesStore.push(action.payload)
    },
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.rootStore.rolesStore = action.payload
    },
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setAccessTokenValue: (state, action: PayloadAction<string>) => {
      localStorage.setItem(localStorageKeys.accessToken, action.payload)
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(localStorageKeys.refreshToken, action.payload)
      state.refreshToken = action.payload
    },
    setExpirationTime: (state, action: PayloadAction<number>) => {
      const paddedTime = action.payload - 240
      state.expirationTime = dayjs().add(paddedTime, 'second').format()
    },
    storeUsernameInLocalStorage: (state) => {
      localStorage.setItem(localStorageKeys.username, state.rootStore.userStore.username)
    },
    logoutCurrentUser: () => {
      localStorage.setItem(localStorageKeys.refreshToken, '')
      localStorage.setItem(localStorageKeys.accessToken, '')
      localStorage.setItem(localStorageKeys.username, '')
      const newState = {
        ...initialState,
        refreshToken: '',
        rootStore: {
          ...initialState.rootStore,
          userStore: {
            ...initialState.rootStore.userStore,
            username: ''
          }
        }
      }
      return newState
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setUsername,
  setAccessTokenValue,
  setExpirationTime,
  setAuthentication,
  setRefreshToken,
  storeUsernameInLocalStorage,
  logoutCurrentUser,
  setRoles
} = authenticationSlice.actions

export default authenticationSlice.reducer
