import { useLazyQuery } from '@apollo/client'
import { Backdrop, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { LOGIN_TOKEN } from './graphql/queries/authenticationQueries'
import { IAuthenticateResponse } from './graphql/types/authenticationTypes'
import FullWidthCenteredLayout from './layouts/FullWidthCenteredLayout'
import AppRoutes from './routes'
import { RootState } from './store'
import { setAccessTokenValue, setExpirationTime, setAuthentication } from './redux/Authentication'
import AppMenu from './components/AppMenu'

const App = () => {
  const refreshToken = useSelector((state: RootState) => state.authenticationReducer.refreshToken)
  const loadingBackdrop = useSelector((state: RootState) => state.settingReducer.loadingBackdrop)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [loginUsingRefreshToken, { data, error }] = useLazyQuery(LOGIN_TOKEN, {
    variables: {
      refreshToken
    }
  })

  useEffect(() => {
    loginUsingRefreshToken()
    setLoading(true)
  }, [loginUsingRefreshToken])

  useEffect(() => {
    if (data) {
      const loginData = data.loginWithToken as IAuthenticateResponse
      dispatch(setAccessTokenValue(loginData.AccessToken))
      dispatch(setExpirationTime(loginData.ExpiresIn))
      dispatch(setAuthentication(true))
      setLoading(false)
    }
    if (error) {
      dispatch(setAuthentication(false))
      console.error(error)
      setLoading(false)
      navigate('/login')
    }
  }, [data, dispatch, error, navigate])

  return (
    <>
      {loading ? (
        <FullWidthCenteredLayout marginTop={50} direction='column'>
          <CircularProgress />
        </FullWidthCenteredLayout>
      ) : (
        <AppMenu>
          <AppRoutes />
          <Backdrop
            open={loadingBackdrop}
            sx={{ color: '#fff', zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </AppMenu>
      )}
    </>
  )
}

export default App
