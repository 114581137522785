import { FunctionComponent, MouseEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, TextField, Box, Typography, Stack, CircularProgress } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import DiamondIcon from '@mui/icons-material/DiamondTwoTone'
import { LOGIN_CREDENTIALS } from '../graphql/queries/authenticationQueries'
import { IAuthenticateResponse } from '../graphql/types/authenticationTypes'
import { RootState } from '../store'
import {
  setAccessTokenValue,
  setAuthentication,
  setExpirationTime,
  setRefreshToken,
  setUsername,
  storeUsernameInLocalStorage
} from '../redux/Authentication'

const LoginView: FunctionComponent = () => {
  const navigate = useNavigate()
  const username = useSelector((state: RootState) => state.authenticationReducer.rootStore.userStore.username)
  const isAuthenticated = useSelector((state: RootState) => state.authenticationReducer.isAuthenticated)
  const dispatch = useDispatch()
  // store user password in local state, we dont want to hang onto this
  const [userPassword, setUserPassword] = useState('')

  // gql lazy query, loads username from user store,
  // and password from local component state
  const [loginWithCreds, { loading, data, error }] = useLazyQuery(LOGIN_CREDENTIALS, {
    fetchPolicy: 'network-only'
  })

  // handle gql hook values here,
  // we do it in use effect to prevent infinite loop of
  // re-renders on the component.
  useEffect(() => {
    if (data && data.loginWithCreds) {
      const loginData = data.loginWithCreds as IAuthenticateResponse
      // store the accessToken, refreshToken and flip authenticated to true
      dispatch(setAccessTokenValue(loginData.AccessToken))
      dispatch(setRefreshToken(loginData.RefreshToken))
      // number of seconds before the accessToken expires
      dispatch(setExpirationTime(loginData.ExpiresIn))
      dispatch(setAuthentication(true))
      dispatch(storeUsernameInLocalStorage())
      // redirect to dashboard
      navigate('/dashboard/user/trials')
    }
  }, [data, dispatch, navigate])

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/user/trials', { replace: true })
    }
  }, [navigate, isAuthenticated])

  useEffect(() => {
    if (error) {
      toast.error(error.message, { theme: 'colored' })
    }
  }, [error])

  // executes the lazy query
  const handleLogin = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    loginWithCreds({
      variables: {
        username,
        password: userPassword
      }
    })
  }

  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Stack justifyContent='stretch' alignItems='center' width='100%' maxWidth={400} px={2}>
        <Stack direction='row' gap={2} alignItems='center'>
          <DiamondIcon fontSize='large' color='primary' />
          <Typography variant='overline' fontSize={16}>
            GEMS Admin Tool
          </Typography>
        </Stack>
        <Typography color='secondary' mt={4} mb={2}>
          Sign in to your Stuller account
        </Typography>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Username'
          name='username'
          autoFocus
          value={username}
          onChange={(event) => {
            dispatch(setUsername(event.target.value))
          }}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          value={userPassword}
          onChange={(event) => {
            setUserPassword(event.target.value)
          }}
        />
        <Button
          size='large'
          fullWidth
          type='submit'
          color='primary'
          variant='contained'
          sx={{ mt: 2, height: 60 }}
          onClick={handleLogin}
        >
          {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Sign In'}
        </Button>
      </Stack>
    </Box>
  )
}

export default LoginView
