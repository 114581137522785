import { Box, Fade, Typography } from '@mui/material'
import { FunctionComponent, ReactNode } from 'react'

interface IPageWithSubTitleLayout {
  subTitle: string
  children: ReactNode
}
const PageWithSubTitleLayout: FunctionComponent<IPageWithSubTitleLayout> = ({ children, subTitle }) => (
  <Box m={4}>
    <Box mb={2}>
      <Fade in>
        <Typography variant='overline' fontSize={20} color='#fff'>
          {subTitle}
        </Typography>
      </Fade>
    </Box>
    {children}
  </Box>
)

export default PageWithSubTitleLayout
