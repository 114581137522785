import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import axios from 'axios'
import { BACKEND_URL } from '../../constants'
import { loginKeyAdmin, logoutKeyAdmin } from '../../redux/KeyCodes'
import UserLevel from './UserLevel'
import { toogleLoadingBackdrop } from '../../redux/Settings'
import { toast } from 'react-toastify'

export default function KeyAdminLogin() {
  const [openDialog, setOpenDialog] = useState(false)
  const dispatch = useDispatch()
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)
  const isSuperAdmin = useSelector((state: RootState) => state.keyCodesReducer.isSuperAdmin)

  const handleClickOpen = () => {
    if (isLogged()) {
      return dispatch(logoutKeyAdmin())
    }
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const isLogged = () => {
    if (isAdmin || isSuperAdmin) {
      return true
    }
    return false
  }

  const onSubmitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const formJson = Object.fromEntries(formData.entries())
    const password = formJson.password
    dispatch(toogleLoadingBackdrop())
    try {
      const response = await axios.post(
        `${BACKEND_URL}/key-admin-login`,
        { password: password },
        { headers: { Authorization: process.env.REACT_APP_current_stage === 'local' ? null : 'allow' } }
      )
      if (response.data.isAdmin === false) {
        toast.error('Invalid Password!', { theme: 'colored' })
      } else {
        dispatch(loginKeyAdmin(response.data))
      }
    } catch (error: any) {
      console.error(error.message)
      toast.error('Error, try again later or contact support team', { theme: 'colored' })
    }
    dispatch(toogleLoadingBackdrop())
    handleClose()
  }

  return (
    <Stack direction={'row'} alignItems={'center'} ml='auto' gap={2}>
      <UserLevel />
      <Button variant='outlined' onClick={handleClickOpen}>
        {isLogged() ? 'Logout' : 'Login'}
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onSubmitLogin
        }}
      >
        <DialogContent>
          <DialogContentText>Enter Admin Password:</DialogContentText>
          <TextField
            autoFocus
            required
            margin='dense'
            id='password'
            name='password'
            type='password'
            fullWidth
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
