import { gql } from '@apollo/client'

export const LIST_USER_TRIALS_BY_USERNAME = gql`
  query verify($username: String!) {
    listUserTrials(input: { username: $username }) {
      items {
        userID
        appId
        trialCreationDate
        trialExpirationDate
        lastLogin
        trialExtensions
      }
    }
  }
`

// AppId:
// be1f6ce0-a17b-11e8-98b7-310b8b71d1dd
// c3ee2bf3-13b0-47ea-b64d-fe05fdd484fb
export const LIST_USER_TRIALS_BY_APP = gql`
  query verify($appId: String!) {
    listUserTrials(input: { appId: $appId }) {
      items {
        appId
        trialCreationDate
        trialExpirationDate
        lastLogin
        trialExtensions
      }
    }
  }
`

export const REMOVE_USER_TRIAL = gql`
  mutation verify($username: String!, $appId: String!) {
    removeUserTrial(username: $username, appId: $appId) {
      username
      userID
      appId
    }
  }
`

export const CREATE_USER_TRIAL = gql`
  mutation verify($username: String!, $applicationId: String!, $trialDays: Int) {
    createUserWithTrial(username: $username, applicationId: $applicationId, trialDays: $trialDays) {
      userID
      appId
    }
  }
`

export const EXTEND_USER_TRIAL = gql`
  mutation extend($username: String!, $applicationId: String!, $trialDays: Int) {
    extendUserTrial(username: $username, applicationID: $applicationId, trialDays: $trialDays) {
      userID
    }
  }
`

export const LIST_STULLER_USERS_BY_SHIP_TO = gql`
  query stullerUsersByShipTo($shipTo: String!) {
    listStullerUsersByShipTo(shipTo: $shipTo) {
      loginId
    }
  }
`
