import { createTheme } from '@mui/material/styles'

const mainColor = '#0099e6'
const mainColorLight = '#00bfff'

export const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#282828'
    },
    primary: {
      main: mainColor,
      light: mainColorLight
    },
    secondary: {
      main: '#bbb'
    },
    error: {
      main: '#f44336'
    },
    success: {
      main: '#9ccc65'
    },
    info: {
      main: '#888'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff',
          '&:hover': {
            backgroundColor: '#0295d9'
          }
        },
        outlinedPrimary: {
          color: mainColorLight,
          borderColor: mainColorLight
        },
        textPrimary: {
          color: mainColorLight
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#333'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#282828',
          maxWidth: 1200
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          color: '#fff'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#333'
        }
      }
    }
  }
})
