import { Box, Container } from '@mui/material'
import { FlexboxProps } from '@mui/system'
import { FunctionComponent, ReactNode } from 'react'

export interface IFullWidthCenteredLayoutProps {
  children: ReactNode
  marginTop: number
  direction: string
}
const FullWidthCenteredLayout: FunctionComponent<IFullWidthCenteredLayoutProps> = ({
  children,
  marginTop,
  direction
}) => (
  <Container maxWidth='md'>
    <Box
      sx={{
        marginTop,
        display: 'flex',
        flexDirection: direction as FlexboxProps,
        alignItems: 'center'
      }}
    >
      {children}
    </Box>
  </Container>
)

export default FullWidthCenteredLayout
