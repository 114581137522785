import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export default function UserLevel() {
  const isAdmin = useSelector((state: RootState) => state.keyCodesReducer.isAdmin)
  const isSuperAdmin = useSelector((state: RootState) => state.keyCodesReducer.isSuperAdmin)
  if (isSuperAdmin) return <div>Super Admin</div>
  else if (isAdmin) return <div>Admin</div>
  return null
}
