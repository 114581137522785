import { Button, Stack, Typography } from '@mui/material'
import PlusIcon from '@mui/icons-material/Add'
import SearchBarComponent from './SearchBarComponent'
import { applicationCollectionData, counterSketch, counterSketchStreaming, matrixGold3 } from '../data/Helpers'

interface ITrialsMenuProps {
  handleAddTrial: (appId: string) => void
  // handleRemoveTrial: (selected: any) => void;
  handleChange: (value: string) => void
  handleSearch: () => void
  ownedUserTrials: Array<string>
  // selectedRow: any;
  readOnlyAccess: boolean
  userSelected: boolean
  searchTerm: string
  isLoading: boolean
}
const TrialsMenuComponent: React.FC<ITrialsMenuProps> = ({
  handleAddTrial,
  handleChange,
  handleSearch,
  searchTerm,
  readOnlyAccess,
  isLoading,
  userSelected,
  ownedUserTrials
}) => {
  // eslint-disable-next-line max-len
  const buttonsDisabled = (appName: string) =>
    readOnlyAccess || (!isLoading && !userSelected) || ownedUserTrials.indexOf(appName) !== -1

  return (
    <Stack spacing={2} mb={3}>
      <SearchBarComponent
        handleSearchChange={handleChange}
        handleSearch={handleSearch}
        placeHolderSearchText='Username'
        searchTextValue={searchTerm}
        elementId='searchUserTrials'
      />
      <Stack direction='row' gap={2} flexWrap='wrap'>
        <Typography variant='overline'>Software Trials:</Typography>
        <Button
          variant='outlined'
          color='primary'
          size='small'
          startIcon={<PlusIcon />}
          onClick={() => handleAddTrial(matrixGold3)}
          disabled={buttonsDisabled('matrixgold')}
        >
          {applicationCollectionData[matrixGold3].title}
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='small'
          startIcon={<PlusIcon />}
          onClick={() => handleAddTrial(counterSketch)}
          disabled={buttonsDisabled('countersketch')}
        >
          {applicationCollectionData[counterSketch].title}
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size='small'
          startIcon={<PlusIcon />}
          onClick={() => handleAddTrial(counterSketchStreaming)}
          disabled={buttonsDisabled('countersketchstreaming')}
        >
          {applicationCollectionData[counterSketchStreaming].title}
        </Button>
      </Stack>
    </Stack>
  )
}
export default TrialsMenuComponent
